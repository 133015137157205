<template>
    <div class="lct-content">
        <!--<app-menu @onActive="onActive" @onTagsActive="onTagsActive"></app-menu>-->
        <div class="lct-main">
            <hot-course-item v-for="(item,index) in lists" :item="item" @click.native="toPath(`/course/${item.cid}/detail`)" :key="item.cid"></hot-course-item>

            <no-data v-if="lists.length<=0"/>
        </div>
        <div class="pagination" v-if="lists.length>0">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-count="total"
                    @current-change="currentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import Search from '@/utils/search'
    export default {
        data() {
            return {
                current: 1,
                total: 0,
                class: 156,
                tags: [],
                keyword: '',
                lists: []
            }
        },
        beforeDestroy() {
            Search.$off("search");
        },
        mounted() {
            Search.$on('search',data => {
                // console.log(data)   //{show:true}
                this.keyword = data.keyword;
                this.page = 1;
                this.getList();
            })
            this.getList();
        },
        methods: {
            onActive(i) {
                if(i){
                    this.class = i
                }else {
                    this.class = null
                }
                this.current = 1;
                this.getList();
            },
            onTagsActive(tags) {
                this.tags = tags;
                this.current = 1;
                this.getList();
            },
            getList() { // 列表
                this.$axios.post(this.apiUrl.courseList_charge, {
                    class: this.class,
                    tags: this.tags.join(','),
                    keyword: this.keyword,
                    page: this.current,
                    pagesize: 20
                }).then(res => {
                    this.lists = res.data.lists;
                    this.total = res.data.allpage;
                })
            },
            currentChange(e) {
                document.documentElement.scrollTop = 0
                this.current = e;
                this.getList();
            },
            toPath(url) {
                this.$router.push({
                    path: url
                })
            }
        }
    }
</script>
